/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2023.
 */

import { SxProps } from '@mui/material';

export const accountOrgAndContractStaticSX: SxProps = {
	pl: 2,
	pt: 0.5,
};
